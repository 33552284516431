.bg{
  background-size: cover;
  background-position: center;

  &-fixed{
      @media screen and (min-width: $md) {
    background-attachment:fixed;
  }
}
}
.cover-image img{
  width: auto;
  min-height: 400px;
}

.insta_img:after{
  content: "";
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  width: 100%;
  height:100%;
}

.insta{

&_item:nth-child(odd) .insta_img{
  @media (min-width: 768px){
    margin-top:100%;
  }
}
}

.insta_item:last-child() {
  @media (max-width: 1023px){
    display: none;
  }
}

.swiper-pagination-bullet{
  background-color: transparent !important;
  border: solid #fff 1px;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active{
  background-color: #fff !important;
  border: solid #fff 1px;
}

.swiper-button-prev, .swiper-button-next{
  color: #fff !important;
}

.feature-image{


  &__medium{
    min-height: 600px;
  }

  &__large{
    min-height: 750px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }

}

.hover {
  .overlay {
        position: absolute;
    transition: 0.5s ease;
    strong {
      color: white;
    }

    .hideit{
      display: none;

    }
.hideborder{
            border: none;
}

  }

  &:hover .overlay {

    .hideit{
      display: block;
    }
    .hideborder{
                border: solid 1px;
    }
  }

  &:hover .overlay:after {
content: "";
  }

  &:hover .image-caption {
    background-color:#1B1B2E;
    border: solid 1px #1B1B2E;
    p {
      color: #FFFFFF;
    }
  }
}

@media screen and (min-width:640px) {

}

.module.instagram:before{
  content: "";
background: #f7fafc;
position: absolute;
top: -100px;
left: 0;
bottom: 0;
right: 0;
width: 100%;
height: 100px;
}
@media screen and (max-width:1024px){
  .module.instagram:before {
    top:100;
  }
}
  .grid-item-fix-height {
    height: 50vh;
    max-height: 400px;
}
@media screen and (min-width:1024px) {
  .grid-item-fix-height {
    height: 60vh;
    max-height: 400px;
  }
  .grid-item:first-child .grid-item-inner{
    margin-right: 2rem;
  }
  .grid-item:nth-child(2) .grid-item-inner{
    margin:0 1rem;
  }
  .grid-item:last-child .grid-item-inner{
    margin-left: 2rem;
    margin-right:0;
  }
}

.text-bg-image{
  position: relative;
}
.text-bg-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  opacity: 0.77;
  background-color:black;
}

.grid-outer{
  position: relative;
}
.grid-outer:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  z-index:-10;
}

.grid-outer.dark-bg:before{
  background-color: #2d3748;
}

@media screen and (min-width:1024px) {
  .grid-outer:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    width: 100%;
    height: 55%;
    max-height: 360px;
    z-index:-10;
  }
}

.grid-outer.light-bg-abc:before{
  background-color: #edf2f7;
}

.branch-border {
  border-top: solid 1px;
  margin: 1.5rem 0;
}

.branch:first-child .branch-border {
  display:none;
}
.branch:nth-child(2) .branch-border {
  display:none;
}

// @media screen and (min-width:640px) {
//   .branch:first-child .branch-border {
//     display:none;
//   }
//   .branch:nth-child(2) .branch-border {
//     display:none;
//   }
// }

@media screen and (min-width:1024px) {
  .branch-border {
    border-top:0;
    margin: 0 2rem 0 0;
    border-left: solid 1px;
  }
  .branch:nth-child(2) .branch-border {
    display:block;
  }
  .branch:first-child .branch-border {
    display:none;
  }
}


.overlay:after{
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-color: rgba(0,0,0,0.7)
}

.popup-contain {
  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 99999;
  p{
  color: #6E6E6E;
}
}
